import gql from 'graphql-tag';

export const ORDER_UPDATED_SUBSCRIPTION = gql`
  subscription onOrderUpdated($orderId: String!) {
    orderUpdated(orderId: $orderId) {
      id
      placeOrderStatus
    }
  }
`;
